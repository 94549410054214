/****************************************************************************
 * Copyright 2018 EPAM Systems
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 ***************************************************************************/

import { h, Component } from 'preact';
import { connect } from 'preact-redux';

import ClipArea from '../component/cliparea';

import { initIcons } from '../state/toolbar';
import { initClipboard } from '../state/hotkeys';
import { initTmplLib } from '../state/templates';

export const AppHidden = connect(
	null,
	dispatch => ({
		onInitTmpls: cacheEl => initTmplLib(dispatch, '', cacheEl)
	})
)(class extends Component {
	componentDidMount() {
		this.props.onInitTmpls(this.cacheEl);
		initIcons(this.cacheEl);
	}
	render = () => (<div className="cellar" ref={(c) => { this.cacheEl = c; }} />)
});

export const AppCliparea = connect(
	null,
	dispatch => (dispatch(initClipboard))
)(ClipArea);
